<template>
    <b-container class="card-body p-0">
      <b-row>
        <b-col>
          <router-link :to="{ name: 'Regbite', params: { id: row.id }}"><h4 class="card-title">{{row.name}}</h4></router-link>
          <div class="mb-2">
            <markdown
              :content="row.comments"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import Markdown from '@/components/Markdown.vue'
export default {
  name: 'Regbite',
  props: ['row', 'username'],
  beforeDestroy () {
  },
  components: {
    Markdown
  },
  computed: {
  },
  created: function () {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  },
  watch: {
  }
}
</script>

<style>
</style>
